* {
	margin: 0;
	padding: 0;
	font-family: Arial, Helvetica, sans-serif;
	box-sizing: border-box;
	font-size: inherit;
}

html, body {
	min-height: 100vh;
}

body {
	background-color: #333;
	color: #eee;
	position: relative;
	font-size: 12px;
	display: flex;
	flex-direction: column;

	@media (min-width: 600px) {
		font-size: 20px;
	}
}

main {
	height: 100%;
	flex: 1;
}

header {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: sticky;
	top: 0;
	padding: 15px;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #fff;
	/* height: 60px; */
	background: linear-gradient(#000D, #0002);
}

h1 {
	margin-bottom: 1em;
	font-size: 21px;

	@media (min-width: 600px) {
		font-size: 42px;
	}
}

h2 {
	font-size: 18px;

	@media (min-width: 600px) {
		font-size: 36px;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.button {
	background-color: #3338;
	border: 1px solid #fff;
	padding: 5px;
	cursor: pointer;
	margin-top: 10px;
	text-align: center;
}

.button:hover {
	background-color: #eee8;
	color: #000;
}

section {
	padding: 0 20px;

	@media (min-width: 600px) {
		padding: 0 120px;
	}
}
