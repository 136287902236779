$block-name: "b-header-menu";

.#{$block-name} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

	&__items {
		display: flex;
		margin-left: -10px;
	}

	&__item {
		list-style-type: none;
		margin: 0 5px;

		&--active {
			text-decoration: underline;
			color: lightskyblue;
		}

		@media (min-width: 600px) {
			margin: 0 10px;
		}
	}

	&__search {
		width: 50%;
	}

	&__profile {
		display: flex;
		align-items: center;
	}

	&__profile-login {
		margin-right: 10px;
	}

	&__login {
		position: fixed;
		top: 70px;
		right: 15px;
		padding: 10px;
		border: 1px solid #FFF;
		background-color: #0006;
		display: none;
	}
}
